import React, { useContext, useState, useEffect, Fragment } from 'react'
import { Col, Row, Alert } from 'react-bootstrap'
import _ from 'lodash'

import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  useReactTable
} from '@tanstack/react-table'

import AppContext from '../../context/AppContext'
import listSpaces from '../use-cases/listSpaces'
import LoadingSpinner from '../../components/common/LoadingSpinner'
import createOrUpdateSpace from 'Spaces/use-cases/createOrUpdateSpace'

import Estimates from './Estimates'
import getPaginationControls from 'components/common/Utils/getPaginationControls'

import getColumns from './SpacesColumns'
import './Space.scss'
import Roles from 'config/Roles'
import moment from 'moment-timezone'

const Spaces = () => {
  const roles = JSON.parse(localStorage.getItem('roles'))
  const { repoFactory, selectedYear } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(false)
  const [products, setProducts] = useState([])
  const [filteredProducts, setFilteredProducts] = useState([])
  const [searchText, setSearchText] = useState('')
  const isReadOnly = !roles.includes(Roles.PRODUCT_MANAGERS)

  useEffect(() => {
    setIsLoading(true)
    listSpaces(
      {
        year: selectedYear,
        token: localStorage.getItem('authToken')
      },
      {
        spacesRepo: repoFactory.spacessRepo(),
        observer: {
          errorReceivingSpaces: () => {
            setIsLoading(false)
            setProducts([])
          },
          receiveSpaces: (userAccessSpaces) => {
            setIsLoading(false)
            setProducts(userAccessSpaces)
          }
        }
      }
    )
  }, [selectedYear, repoFactory, setProducts, setIsLoading])

  useEffect(() => {
    const clonedProducts = _.cloneDeep(products)

    if (searchText) {
      const trimedSearchText = searchText.trim().toLocaleLowerCase()
      const filteredItems = clonedProducts.filter((item) => {
        if (item.element4?.toLowerCase().includes(trimedSearchText)) {
          return true
        }
        const { estimates } = item
        const filteredEstimates = [...estimates].filter((e) => {
          if (
            e.accountName?.toLowerCase().includes(trimedSearchText) ||
            e.accountId?.toLowerCase().includes(trimedSearchText)
          ) {
            return true
          }
          return false
        })

        if (filteredEstimates.length) {
          item.estimates = [...filteredEstimates]
          return true
        }

        return false
      })
      setFilteredProducts(filteredItems)
    } else {
      setFilteredProducts(clonedProducts)
    }
  }, [searchText, products])

  const parseTotalsInProduct = (product) => {
    const parsedProduct = { ...product }

    parsedProduct.estimates = product.estimates.map((estimate) => {
      delete estimate.allocation
      const { breakdown } = estimate

      if (!breakdown || breakdown.length === 0) {
        return estimate
      }

      estimate.breakdown = breakdown.map((b) => {
        if (typeof b.total === 'string') {
          b.total = parseFloat(b.total || 0)
          return b
        }

        return b
      })

      return estimate
    })

    return parsedProduct
  }

  const save = (product) => {
    setIsLoading(true)

    const parsedProduct = parseTotalsInProduct(product)

    createOrUpdateSpace(
      {
        year: selectedYear,
        token: localStorage.getItem('authToken'),
        element1: parsedProduct.element1,
        element2: parsedProduct.element2,
        element3: parsedProduct.element3,
        element4: parsedProduct.element4,
        estimates: parsedProduct.estimates
      },
      {
        spacesRepo: repoFactory.spacessRepo(),
        observer: {
          errorUpdatingSpace: (error) => {
            setIsLoading(false)
          },
          receiveSpaceUpdates: (response) => {
            setIsLoading(false)
          }
        }
      }
    )
  }

  const table = useReactTable({
    data: filteredProducts,
    columns: getColumns(save, isReadOnly),
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: getPaginationRowModel()
  })

  const updateEstimates = (productId, accountId, estimates) => {
    const productIndex = products.findIndex((prod) => prod.element4 === productId)
    const accountIndex = products[productIndex].estimates.findIndex((estimate) => estimate.accountId === accountId)
    const newItems = [...products]
    newItems[productIndex].estimates[accountIndex].breakdown = estimates
    setProducts(newItems)
  }

  const onSearchKey = (event) => {
    const { value } = event.target
    setSearchText(value)
  }

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {products?.length === 0 && !isLoading ? <Alert varient="warning">No Products have been mapped yet</Alert> : ''}
      {products?.length ? (
        <div className={`space-section ${isReadOnly ? 'space-section-readonly' : ''}`}>
          <h1>Products</h1>
          <Row className="mb-2">
            <Col md={4}>
              <input type="text" className="form-control" placeholder="Search ..." onChange={onSearchKey} />
            </Col>
            <Col md={8}>{getPaginationControls(table)}</Col>
          </Row>

          <div className="table-responsive">
            <table className="space-products-table table table-bordered">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th key={header.id}>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <Fragment key={row.id}>
                    <tr>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                      ))}
                    </tr>
                    {row.getIsExpanded() && (
                      <tr className="expanded-row">
                        <td colSpan={row.getVisibleCells().length}>
                          {row.original.estimates.map((account, index) => {
                            const { allocation } = account
                            return (
                              <div key={`${index}-${account.id}`}>
                                <table className="table-accounts-space table table-bordered">
                                  <thead>
                                    <tr>
                                      <th>Account</th>
                                      <th>Environment</th>
                                      <th>Allocation (Updated)</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        {account.accountName} - {account.accountId}
                                      </td>
                                      <td>{allocation?.[0].environment}</td>
                                      <td>
                                        {allocation &&
                                          allocation.map((a, i) => (
                                            <div key={`allocation-${i}`}>
                                              <strong>{a.percentage}%</strong> {a.startDate} - {a.endDate}{' '}
                                              {a.insertedDate &&
                                                `(${moment
                                                  .utc(a.insertedDate)
                                                  .local()
                                                  .format('MM-DD-YYYY hh:mm:ss A')})`}
                                            </div>
                                          ))}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>

                                <Estimates
                                  productId={row.original.element4}
                                  account={account}
                                  update={updateEstimates}
                                  isReadOnly={isReadOnly}
                                />
                              </div>
                            )
                          })}
                        </td>
                      </tr>
                    )}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default Spaces
